import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import VueClipboard from 'vue-clipboard2'
import Misc from './plugins/Misc'
import Toasted from 'vue-toasted'
import VueMeta from 'vue-meta'
import VModal from 'vue-js-modal'
import VueMask from 'v-mask'
Vue.use(VueMask)

import * as Sentry from '@sentry/vue'
import '@/assets/scss/_theming.scss'
import i18n from './i18n'

import routes from './components/helpers/Router'
const router = new VueRouter({
  routes,
  mode: 'history',
  //mode: 'hash'
})

function initSentry() {
  if (process.env.NODE_ENV !== 'production') {
    return
  }

  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN_URL,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: process.env.VUE_APP_SENTRY_DOMAINS.split(','),

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })

  console.log('sentry inited')

  if (localStorage.sUsername) {
    Sentry.setUser({
      username: localStorage.sUsername + ' (' + localStorage.sRole + ')',
    })
  }
}

initSentry()

function hasUpperCase(str) {
  return str !== str.toLowerCase()
}

router.beforeEach((to, from, next) => {
  if (
    //!to.fullPath.includes('verify-email?token') &&
    hasUpperCase(to.path)
  ) {
    next({
      path: to.path.toLowerCase(),
      query: to.query,
    })
    return
  }

  const isAuthenticated = localStorage.getItem('token')

  if (
    isAuthenticated &&
    ['/signup', '/signup?reg=1', '/signup?reg=0'].includes(to.fullPath)
  )
    next({ path: '/' })

  if (
    !isAuthenticated &&
    ![
      '/',
      '/?logout',
      '/signup',
      '/signup?reg=1',
      '/signup?reg=0 ',
      '/become-a-model',
      '/users-filter',
    ].includes(to.fullPath)
  ) {
    // Запоминаем страницу на которую человек хотел перейти,
    // чтоб после авторизации сделать редирект на нее.
    localStorage.setItem('redirectTo', to.fullPath)
  }

  if (!isAuthenticated && to?.query?.ref) {
    localStorage.setItem('token_ref', to?.query?.ref)
  }

  if (
    !isAuthenticated &&
    to.name !== 'home' &&
    ![
      '/signup',
      '/signup?reg=1',
      '/signup?reg=0 ',
      '/become-a-model',
      '/forgot',
      '/terms',
      '/cookie',
      '/privacy',
      '/contract',
      '/dmca',
      '/refund-policy',
      '/password-reset',
      '/creators',
      '/admin',
      '/users-filter',
      '/404-page-not-found',
      '/usc',
      '/twitter/callback',
    ].includes(to.path) &&
    !to.meta?.isUserPage
    /*&& !to.meta?.isPublicPage*/
  ) {
    next({ path: '/' })
  } else next()
})

Vue.use(Vuex)
Vue.use(Toasted)
Vue.use(VueMeta)

import stores from './components/helpers/Store'
import './registerServiceWorker'
const store = new Vuex.Store(stores)

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(Misc)
Vue.use(VueClipboard)
Vue.use(VModal)

new Vue({
  router,
  i18n,
  store,
  Toasted,
  render: (h) => h(App),
  mounted() {
    this.$isStandalone()
      ? document.body.classList.add('standalone')
      : document.body.classList.add('browser')

    // 9 / 16 screen = 0.5625
    let aspect =
      window.screen.width / window.screen.height < 0.5625
        ? 'aspect-wide'
        : 'aspect-default'
    document.body.classList.add(aspect)
  },
}).$mount('#app')
