export default [
  {
    path: '/admin',
    component: () => import('../layout/admin/TheLayout.vue'),
    children: [
      {
        path: '/admin',
        components: {
          default: () => import('../pages/admin/PageDashboard.vue'),
          login: () => import('../pages/admin/PageLogin.vue'),
        },
        meta: { isPublicPage: true },
      },
      {
        path: '/admin/stop-words',
        component: () => import('../pages/admin/PageStopWords.vue'),
      },
      {
        path: '/admin/users/:type?',
        component: () => import('../pages/admin/PageUsers.vue'),
      },
      {
        path: '/admin/users/edit/:id',
        component: () => import('../pages/admin/PageUsersEdit.vue'),
      },
      {
        path: '/admin/subscriptions/:type?',
        component: () => import('../pages/admin/PageSubscriptions.vue'),
      },
      {
        path: '/admin/payments/:type?',
        component: () => import('../pages/admin/PagePayments.vue'),
      },
      {
        path: '/admin/payouts/:type?',
        component: () => import('../pages/admin/PagePayouts.vue'),
      },
      {
        path: '/admin/message/:id',
        component: () => import('../pages/admin/PageMessage.vue'),
        name: 'Admin message',
      },
      {
        path: '/admin/payouts/transactions/:id',
        component: () => import('../pages/admin/PageTransactions.vue'),
      },
      {
        path: '/admin/posts',
        component: () => import('../pages/admin/PagePosts.vue'),
      },
      {
        path: '/admin/referral',
        component: () => import('../pages/admin/PageReferral.vue'),
      },
      {
        path: '/admin/popular',
        component: () => import('../pages/admin/PagePopular.vue'),
      },
      {
        path: '/admin/vault',
        component: () => import('../pages/admin/PageVault.vue'),
      },
      {
        path: '/admin/reports',
        component: () => import('../pages/admin/PageReports.vue'),
      },
      {
        path: '/admin/settings',
        component: () => import('../pages/admin/PageSettings.vue'),
      },
    ],
  },
  {
    path: '/twitter/callback',
    name: 'twitter-callback',
    component: () => import('../pages/PageTwitterCallback.vue'),
    meta: { isPublicPage: true },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../pages/public/PagePrivacy.vue'),
    meta: { isPublicPage: true },
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../pages/public/PageTerms.vue'),
    meta: { isPublicPage: true },
  },
  {
    path: '/contract',
    name: 'contract',
    component: () => import('../pages/public/PageContract.vue'),
    meta: { isPublicPage: true },
  },
  {
    path: '/cookie',
    name: 'cookie',
    component: () => import('../pages/public/PageCookie.vue'),
    meta: { isPublicPage: true },
  },
  {
    path: '/dmca',
    name: 'dmca',
    component: () => import('../pages/public/PageDMCA.vue'),
    meta: { isPublicPage: true },
  },
  {
    path: '/usc',
    name: 'usc',
    component: () => import('../pages/public/PageUSC.vue'),
    meta: { isPublicPage: true },
  },
  {
    path: '/refund-policy',
    name: 'refund policy',
    component: () => import('../pages/public/PageRefundPolicy.vue'),
    meta: { isPublicPage: true },
  },
  {
    path: '/3d-secure',
    component: () => import('../pages/ThreeDSecure.vue'),
  },
  {
    path: '/',
    component: () => import('../layout/user/TheLayout.vue'),
    children: [
      {
        path: '/404-page-not-found',
        name: '404 page not found',
        components: {
          default: () => import('../pages/NotFoundPage.vue'),
          login: () => import('../pages/NotFoundPage.vue'),
        },
      },
      {
        path: '/live-is-over',
        name: 'live-is-over',
        component: () => import('../pages/IsOverLive.vue'),
      },
      {
        path: '/live',
        name: 'live',
        component: () => import('../pages/StartLive.vue'),
      },
      {
        path: '/watch-live/:id',
        name: 'watch-live',
        component: () => import('../pages/WatchLive.vue'),
      },
      {
        path: '/sessions-manager/:id',
        component: () => import('../pages/SessionsManager.vue'),
      },
      {
        path: '/test-pay',
        name: 'test-pay',
        component: () => import('../pages/PageTestPay.vue'),
      },

      {
        path: '/verify-email',
        component: () => import('../pages/VerifyEmail.vue'),
      },
      {
        path: '/payments',
        component: () => import('../pages/PagePayments.vue'),
      },
      {
        path: '/payouts',
        component: () => import('../pages/PagePayouts.vue'),
      },
      {
        path: '/verification',
        component: () => import('../pages/AutomaticalVerifications.vue'),
      },
      {
        path: '/manual-verification',
        component: () => import('../pages/PageVerificationModel.vue'),
      },
      {
        path: '/notifications',
        components: {
          default: () => import('../pages/PageNotifications.vue'),
          side: () => import('../layout/AppSide.vue'),
        },
        name: 'notifications',
      },
      {
        path: '/subscriptions',
        component: () => import('../pages/PageSubscriptions.vue'),
        name: 'subscriptions',
      },
      {
        path: '/creators',
        components: {
          default: () => import('../pages/PageCreators.vue'),
          side: () => import('../layout/AppSide.vue'),
        },
        name: 'creators',
        meta: { isCreatorsPage: true, isPublicPage: true },
      },
      {
        path: '/users-filter',
        components: {
          default: () => import('../pages/PageUsersFilter.vue'),
          side: () => import('../layout/AppSide.vue'),
        },
        name: 'users-filter',
        meta: { isCreatorsPage: true, isPublicPage: true },
      },
      {
        path: '/payment/failure',
        component: () => import('../pages/PagePaymentFailure.vue'),
      },
      {
        path: '/payment/success/:gateway',
        component: () => import('../pages/PagePaymentSuccess.vue'),
      },
      {
        path: '/messages/:id?',
        component: () => import('../pages/PageMessages.vue'),
        name: 'messages',
      },
      {
        path: '/mass-messages/:id?',
        component: () => import('../pages/PageMessagesMass.vue'),
        name: 'mass-messages',
      },
      {
        path: '/referral',
        component: () => import('../pages/PageReferral.vue'),
        name: 'referral',
      },
      {
        path: '/become-a-model',
        component: () => import('../pages/PageBecomeAModel.vue'),
        name: 'become-a-model',
        meta: { isBecomeAModelPage: true, isPublicPage: true },
      },
      {
        path: '/lists',
        component: () => import('../pages/PageLists.vue'),
      },
      {
        path: '/lists/:id',
        component: () => import('../pages/PageList.vue'),
        name: 'list',
      },
      {
        path: '/message/:id',
        component: () => import('../pages/PageMessage.vue'),
        name: 'message',
      },
      {
        path: '/vault',
        component: () => import('../pages/PageVault.vue'),
      },
      {
        path: '/bookmarks',
        component: () => import('../pages/PageBookmarks.vue'),
        name: 'bookmarks',
      },
      {
        path: '/forgot',
        components: {
          default: () => import('../pages/PageForgot.vue'),
          login: () => import('../pages/PageForgot.vue'),
        },
        meta: { isPublicPage: true },
      },
      {
        path: '/password-reset',
        components: {
          default: () => import('../pages/PagePasswordReset.vue'),
          login: () => import('../pages/PagePasswordReset.vue'),
        },
        meta: { isPublicPage: true },
      },
      {
        path: '/signup',
        components: {
          default: () => import('../pages/PageSignup.vue'),
          login: () => import('../pages/PageSignup.vue'),
        },
        meta: { isPublicPage: true },
      },
      {
        path: '/settings/messages',
        component: () => import('../pages/PageMessageSettings.vue'),
      },
      {
        path: '/settings/price',
        component: () => import('../pages/PagePrice.vue'),
      },
      {
        path: '/settings/notifications',
        component: () => import('../pages/PageNotificationsSettings.vue'),
      },
      {
        path: '/settings/social-media',
        component: () => import('../pages/PageSocialMedia.vue'),
      },
      {
        path: '/settings/email',
        component: () => import('../pages/PageEmail.vue'),
      },
      {
        path: '/settings/password',
        component: () => import('../pages/PagePassword.vue'),
      },
      {
        path: '/settings',
        component: () => import('../pages/PageSettings.vue'),
      },
      {
        path: '/posts/create',
        component: () => import('../pages/PageNewPost.vue'),
      },
      {
        path: '/posts/edit/:id',
        component: () => import('../pages/PageNewPost.vue'),
      },
      {
        path: '/search',
        components: {
          default: () => import('../pages/PageSearch.vue'),
          side: () => import('../layout/AppSide.vue'),
        },
        name: 'search',
      },
      {
        path: '/:username',
        component: () => import('../pages/PageUser.vue'),
        meta: { isUserPage: true, isPublicPage: true },
      },
      {
        path: '/:id/:username',
        component: () => import('../pages/PagePost.vue'),
      },
      {
        path: '/',
        name: 'home',
        components: {
          default: () => import('../pages/PageHome.vue'),
          login: () => import('../pages/PageLogin.vue'),
          side: () => import('../layout/AppSide.vue'),
        },
      },
    ],
  },
]
