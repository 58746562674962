import { currencySymbols } from '@/components/helpers/currencies'

export default class Bundle {
  id = 0
  discount = 0
  months = 0
  user = null
  price = 0

  constructor(data) {
    if (!data) {
      return
    }
    this.id = data.id
    this.discount = data.discount
    this.months = data.months
    this.price = data.price
  }

  title() {
    const currentCurrency = JSON.parse(localStorage.currentUser).currency
    const total = currencySymbols[currentCurrency] + this.price
    return [this.months, total, this.discount]
  }
}
